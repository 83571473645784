import styled from "styled-components";

export const LandingMoneyTransfer = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 36px 16px;
  flex-direction: column;
  border-radius: 16px;
  position: relative;

  & .brownArr {
    width: 86px;
    height: 43px;
    right: 80px;
    top: 389px;
    position: absolute;
    z-index: 1;
  }

  & .blueArr {
    width: 86px;
    height: 43px;
    top: 338px;
    right: 170px;
    position: absolute;
    z-index: 1;
  }

  & .moneyTrans1 {
    width: 72px;
    height: 90px;
    right: 206px;
    top: 298px;
    position: absolute;
    transform: scale(0.8);
  }
  & .moneyTrans2 {
    width: 41px;
    height: 68px;
    right: 94px;
    top: 327px;
    position: absolute;
    transform: scale(0.8);
  }
  & .moneyTrans3 {
    width: 41px;
    height: 50px;
    right: 176px;
    bottom: 113px;
    position: absolute;
    transform: scale(0.8);
  }
  & .moneyTrans4 {
    width: 28px;
    height: 34px;
    right: 98px;
    bottom: 116px;
    position: absolute;
    transform: scale(0.8);
  }
`;

export const LandingMoneyTransferTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 24px;
`;

export const LandingMoneyTransferImgWrap = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

export const GreyBoxSmall = styled.div`
  width: 136px;
  height: 114px;
  background: #fafafa;
  border-radius: 20px;
  flex-shrink: 0;

  &:first-child {
    margin-bottom: 6px;
  }
`;

export const GreyBoxBig = styled.div`
  background: #fafafa;
  border-radius: 20px;
  width: 148px;
  height: 235px;
  margin-left: 12px;
  flex-shrink: 0;
`;

export const LandingMoneyTransferList = styled.ul`
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  gap: 8px;
  margin-bottom: 16px;

  & li {
    position: relative;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 8px;
    background: #000000;
    border-radius: 14px;
  }
`;
